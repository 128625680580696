import React from 'react'

const Dashboard = () => {
  return (
    <>
      <h2 className='text-center mt-3'>Welcome To Narayan Institute Of Management & Technology Admin Panel!!!!!!</h2>
    </>
  )
}

export default Dashboard